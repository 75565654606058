import "./MyApp.css"; // 外部CSSファイルを使用する場合

function App() {
  return (
    <div className="image-container">
      <img src="/images/flyer.png" alt="Flyer" />
    </div>
  );
}

export default App;
